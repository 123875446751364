import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  ListActions,
  BooleanField,
  usePermissions,
} from "react-admin";
import { isInternalAdmin } from "../../auth-provider";

export const CampaignList = ({ ...props }) => {
  const permissions = usePermissions();
  
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "updated", order: "DESC" }}
      actions={isInternalAdmin(permissions.permissions) ? <ListActions /> : null}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField
          source="createdBy"
          reference="users"
          link={isInternalAdmin(permissions.permissions) ? "show" : false}
        >
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField
          source="organisationId"
          reference="organisations"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="occupationId"
          reference="occupations"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="normType" label="Norm" />
        <NumberField
          source="amountOfCompletedCandidates"
          label="Completed Candidates"
        />
        <NumberField source="amountOfCandidates" label="Total Candidates" />
        <BooleanField source="recruiterEnabled" />
        <TextField source="assessmentsSource" />
        <DateField source="startDateTime" />
        <DateField source="endDateTime" />
        <DateField source="updated" />
        <TextField source="language" />
      </Datagrid>
    </List>
  );
};
