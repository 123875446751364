import * as React from "react";
import {
  BooleanInput,
  Create,
  email,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { useFormState } from "react-hook-form";
import { useRoles } from "../../hooks/user/useRoles";
import { useLocation } from "react-router";

export const UserCreate = (props) => {
  const location = useLocation();
  const internalUsers = location?.pathname.includes("internal-users")
  const roles = useRoles(internalUsers ? "INTERNAL" : "ORGANISATION_LEVEL");

  return (
    <Create {...props}>
      <SimpleForm toolbar={<CreateUserToolbar internalUsers={internalUsers} />}>
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <TextInput
          source="email"
          type="email"
          validate={[required(), email()]}
        />
        <TextInput source="password" validate={[required()]} />
        <SelectInput
          source="role"
          choices={roles}
          optionValue="role"
          optionText="role"
          validate={[required()]}
        />
        {!internalUsers && (
          <ReferenceInput source="organisationId" reference="organisations">
            <SelectInput
              optionText="name"
              validate={[required()]}
              label="Organisation"
            />
          </ReferenceInput>
        )}
        {!internalUsers && (
          <BooleanInput source="sendWelcomeEmail" defaultValue={true} />
        )}
      </SimpleForm>
    </Create>
  );
};

const CreateUserToolbar = ({ internalUsers, ...props }) => {
  const { values, isValid } = useFormState();
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext();

  const onSuccess = () => {
    notify(
      values.sendWelcomeEmail
        ? "User created. Welcome Email was delivered."
        : "User created."
    );
    redirect(
      internalUsers
        ? `/internal-users`
        : `/organisations/${record.organisationId}/show/users`
    );
  };

  const onFailure = () => notify("Error while creating User.", "warning");

  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={!isValid}
        mutationOptions={{ onSuccess, onFailure }}
      />
    </Toolbar>
  );
};
