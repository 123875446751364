import * as React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Link,
} from "@mui/material";
import { useRecordContext } from "react-admin";
import { AVA_ADMIN_URL } from "../../Routes";

export const ResultShow = () => {
  const record = useRecordContext();

  return (
    <Box margin={1}>
      <Typography variant="h6" gutterBottom component="div">
        Result Breakdown
      </Typography>
      <Table size="small">
        <TableBody>
          {record.competencyResults.map((competency) => (
            <TableRow key={competency.competencyName}>
              <TableCell align="left">{competency.competencyName}</TableCell>
              <TableCell align="left">{competency.score}</TableCell>
              <TableCell align="right">
                {competency.avaRespondentId ? (
                  <Link
                    href={`${AVA_ADMIN_URL}/respondents/show/${competency.avaRespondentId}`}
                    target="_blank"
                  >
                    AVA admin
                  </Link>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
