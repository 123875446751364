import { fetchUtils, GET_MANY_REFERENCE } from "react-admin";

const initResultsProvider = (apiUrl, httpClient = fetchUtils.fetchJson, defaultCrudHandler, convertDataRequestToHTTP) => {
    return (type, resource, params) => {
        switch (type) {
            case GET_MANY_REFERENCE:
                return httpClient(`${apiUrl}/results?projectIds=${params.id}&includeDeleted=true`)
                    .then(response => ({
                        data: response.json.content,
                        total: parseInt(response.json.totalElements, 10)
                    }));

            default:
                return defaultCrudHandler(type, resource, params);
        }
    }
};

export default initResultsProvider;