import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
  Filter,
  SearchInput,
  NumberField,
  ReferenceField,
  DateField,
  ShowButton,
  BooleanField,
} from "react-admin";
import SimpleChipField from "../common/utils";
import DownloadCV from "./download-cv-button";

const ListFilters = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const CandidatePollinationList = ({ ...props }) => (
  <List
    {...props}
    filters={<ListFilters />}
    perPage={25}
    sort={{ field: "updated", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="profile.name" label="First Name" />
      <TextField source="profile.lastName" label="Last Name" />
      <EmailField source="profile.email" label="Email" />
      <DateField source="created" label="Added" />
      <TextField source="profile.location.name" label="Location" />
      <NumberField source="overallScore" label="Overall Score" />
      <ReferenceField
        source="organisationId"
        reference="organisations"
        label="Organisation"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="profile.role" label="Role" />
      <TextField
        source="profile.yearsOfExperience"
        label="Seniority"
        sortable={false}
      />
      <TextField source="profile.industry" label="Industry" />
      <TextField source="profile.careerStage" label="Career Stage" />
      <FunctionField
        label="Current Salary"
        source="profile.annualSalary.amount"
        render={(record) =>
          record.profile?.annualSalary &&
          `${record.profile?.annualSalary.currency} ${record.profile?.annualSalary.amount} `
        }
      />
      <FunctionField
        label="Desired Salary"
        source="desiredSalary.amount"
        render={(record) =>
          `${record.desiredSalary.currency} ${record.desiredSalary.amount} `
        }
      />
      <ArrayField
        source="workLocations"
        label="Work Locations"
        sortable={false}
      >
        <SingleFieldList linkType={false}>
          <ChipField source="location.name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="skills" label="Skills" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="jobModality" label="Job Modality" sortable={false}>
        <SingleFieldList linkType={false}>
          <SimpleChipField />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="languages" label="Languages" sortable={false}>
        <SingleFieldList linkType={false}>
          <SimpleChipField />
        </SingleFieldList>
      </ArrayField>
      <BooleanField source="openForGroupInterview" label="Is Open For Group" />
      <ShowButton />
      <DownloadCV />
    </Datagrid>
  </List>
);
