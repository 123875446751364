import {
    TextField,
    List,
    Datagrid,
    DateField,
    FunctionField,
    ReferenceField,
    EmailField,
    DeleteButton,
    ListActions,
    usePermissions
} from "react-admin";
import * as React from "react";
import {isInternalAdmin, readOnly} from "../../auth-provider";

const SubReference = ({ translateChoice, children, ...props }) => (
    <ReferenceField link={false} {...props}>{children}</ReferenceField>
);

export const HiredCandidatePollinationList = ({...props}) => {
    const permissions = usePermissions();

    return (
        <List {...props} perPage={25} sort={{ field: 'created', order: 'DESC' }} actions={isInternalAdmin(permissions.permissions) ? <ListActions/> : null}>
        <Datagrid bulkActionButtons={false}>
            <ReferenceField source="candidatePollinationId" reference="candidate-pollination" label="Name" link={false}>
                <SubReference source="candidateId" reference="candidates" >
                    <TextField source="name"/>
                </SubReference>
            </ReferenceField>
            <ReferenceField source="candidatePollinationId" reference="candidate-pollination" label="Last Name" link={false}>
                <SubReference source="candidateId" reference="candidates" >
                    <TextField source="lastName"/>
                </SubReference>
            </ReferenceField>
            <ReferenceField source="candidatePollinationId" reference="candidate-pollination" label="Email" link={false}>
                <SubReference source="candidateId" reference="candidates" >
                    <EmailField source="email"/>
                </SubReference>
            </ReferenceField>
            <ReferenceField source="shortlistId" reference="shortlists" label="Shortlist">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="created" label="Hired On"/>
            <ReferenceField source="createdBy" reference="users" link={isInternalAdmin(permissions.permissions) ? "show" :  false} label="Hired By" emptyText={"System"}>
                <TextField source="firstName"/>
            </ReferenceField>
            <ReferenceField source="organizationId" reference="organisations" label="Organisation">
                <TextField source="name"/>
            </ReferenceField>
            <FunctionField label="Salary" source="salary.amount" render={record => record.salary?.amount && `${record.salary.currency} ${record.salary.amount} `} />
            {!readOnly(permissions.permissions) &&
            <DeleteButton />}
        </Datagrid>
    </List>
    );
}