import { Box, Chip, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid as DataGridMUI } from "@mui/x-data-grid";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  usePermissions,
  useShowController,
  useGetMany,
} from "react-admin";
import ApiClient from "../../ApiClient";
import { isInternalAdmin } from "../../auth-provider";
import API from "../../Routes";
import { ResultShow } from "./result-show";

const STAGE = process.env.REACT_APP_STAGE || "staging";
const EMPLOYEE_ENDPOINT =
  STAGE === "production"
    ? "https://app.thrivetech.co/employees"
    : "https://app.chasana.biz/employees";

const ProjectShowTitle = () => {
  const record = useRecordContext();
  return <span>Project {record ? `"${record.name}"` : ""}</span>;
};

const SubReference = ({ translateChoice, children, ...props }) => (
  <ReferenceField {...props}>{children}</ReferenceField>
);

const Competencies = () => {
  const record = useRecordContext();
  const competencyIds = record.competencies.map((competency) => competency.id);
  const { data } = useGetMany("competencies", { ids: competencyIds });

  return (
    <Box sx={{marginBottom: '8px'}}>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        gutterBottom
        sx={{ fontSize: "12px", marginBottom: "8px" }}
      >
        Competencies
      </Typography>
      <Stack direction="row" spacing={1} label="Competencies">
        {data?.length > 0
          ? data.map((competency) => <Chip label={competency.name} />)
          : null}
      </Stack>
    </Box>
  );
};

export const ProjectShow = ({ ...props }) => {
  const [overall, setOverall] = useState({ assessmentOverallResults: [] });
  const permissions = usePermissions();
  const { record } = useShowController();

  useEffect(() => {
    let didCancel = false;
    if (record) {
      (async () => {
        const overallResponse = await ApiClient.fetch(
          `${API.PROJECT_RESULTS.OVERALL}?projectId=${record.id}`
        );

        if (!didCancel) {
          if (overallResponse.status === 200) {
            setOverall(overallResponse.json);
          }
        }
      })();
    }

    return () => {
      didCancel = true;
    };
  }, [record]);

  return (
    <Show {...props} title={<ProjectShowTitle />}>
      <TabbedShowLayout>
        <Tab label="Employee - Scores">
          <ReferenceManyField
            label={false}
            reference="results"
            target="projectIds"
            filter={{ includeDeleted: true, projectIds: record?.id }}
            perPage={200}
          >
            <Datagrid expand={<ResultShow />} bulkActionButtons={false}>
              <TextField source="employeeId" label="Employee Id" />
              <ReferenceField
                source="employeeId"
                reference="employees"
                label="Email"
                link={false}
              >
                <EmailField source="email" />
              </ReferenceField>
              <ReferenceField
                source="employeeId"
                reference="employees"
                label="External Correlation Id"
                link={false}
              >
                <TextField source="externalCorrelationId" />
              </ReferenceField>
              <ReferenceField
                source="employeeId"
                reference="employees"
                label="Name"
                link={false}
              >
                <FunctionField
                  render={(record) => `${record.name} ${record.lastName}`}
                />
              </ReferenceField>
              <ReferenceField
                source="employeeId"
                reference="employees"
                label="Deleted"
                link={false}
              >
                <BooleanField source="deleted" />
              </ReferenceField>
              <ReferenceField
                source="employeeId"
                reference="employees"
                label="Team"
                link={false}
              >
                <SubReference
                  source="teamId"
                  reference="projectTeams"
                  link={false}
                >
                  <TextField source="name" />
                </SubReference>
              </ReferenceField>
              <NumberField source="overallCompetencyScore" />
              <ReferenceField
                source="employeeId"
                reference="employees"
                label="Employee Link"
                link={false}
              >
                <FunctionField
                  render={(record) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${EMPLOYEE_ENDPOINT}/${record.id}?token=${record.token}`}
                    >
                      Employee Link
                    </a>
                  )}
                />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Overall">
          <Box margin={-1} marginLeft={-2} sx={{ height: 400, width: "100%" }}>
            <DataGridMUI
              getRowId={(row) => row.type}
              hideFooterPagination={true}
              disableColumnMenu={true}
              rows={overall.overallResults}
              columns={[
                {
                  field: "type",
                  sortable: false,
                  headerName: "Evaluation Type",
                  width: 250,
                },
                {
                  field: "score",
                  headerName: "Score",
                  sortable: false,
                  width: 150,
                },
                {
                  field: "fit",
                  headerName: "Fit",
                  sortable: false,
                  width: 150,
                },
                {
                  field: "amountOfEmployees",
                  sortable: false,
                  headerName: "Amount of Employees",
                  width: 200,
                },
                {
                  field: "amountOfCompletedEmployees",
                  sortable: false,
                  headerName: "Amount of Completed Employees",
                  width: 250,
                },
              ]}
            />
          </Box>
        </Tab>
        <Tab label="Summary">
          <TextField source="id" />
          <TextField source="name" />
          <ReferenceField
            source="organisationId"
            reference="organisations"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="createdBy"
            reference="users"
            link={isInternalAdmin(permissions.permissions) ? "show" : false}
          >
            <TextField source="firstName" />
          </ReferenceField>
          <DateField source="startDateTime" />
          <Competencies />
          <ArrayField source="surveys" label="Surveys" sortable={false}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="type" />
              <TextField source="surveyFrequency" />
            </Datagrid>
          </ArrayField>
          <NumberField source="amountOfEmployees" />
          <TextField source="type" />
          <TextField source="language" label="Language" />
          <TextField source="timezone" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
