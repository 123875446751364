import {
  ArrayInput,
  Create,
  DeleteButton,
  Edit,
  required,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useRecordContext,
  useRedirect,
  useShowController,
} from "react-admin";
import { useFormState } from "react-hook-form";
import ApiClient from "../../../ApiClient";
import API from "../../../Routes";
import * as React from "react";
import { useLocation } from "react-router";

export const ImprovementsEditor = (props) => {
  const location = useLocation();
  const isCreateView = location?.pathname.includes("create");
  const { record } = useShowController();

  return isCreateView ? (
    <Create {...props} redirect={`/assessments/${record.assessmentId}/show`}>
      <ImprovementsEditorForm />
    </Create>
  ) : (
    <Edit {...props} redirect={`/assessments/${record.assessmentId}/show`}>
      <ImprovementsEditorForm />
    </Edit>
  );
};

const ImprovementsEditorForm = (props) => (
  <SimpleForm {...props} toolbar={<ImprovementsEditorToolbar />}>
    <TextInput
      source="description"
      label="Description"
      multiline
      fullWidth
      validate={[required()]}
    />
    <ArrayInput source="improvements">
      <SimpleFormIterator>
        <TextInput label="Improvement" />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);

const ImprovementsEditorToolbar = (props) => {
  const { isValid } = useFormState();
  const redirect = useRedirect();
  const record = useRecordContext();

  const handleDelete = async () => {
    await ApiClient.fetch(API.ASSESSMENTS.IMPROVEMENTS.DELETE(record.id), {
      method: "DELETE",
    });
    redirect(`/assessments/${record.assessmentId}/show/2`);
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={!isValid}
        mutationOptions={{
          onSuccess: () =>
            redirect(`/assessments/${record.assessmentId}/show/2`),
        }}
      />
      <DeleteButton
        mutationOptions={{
          onSuccess: handleDelete,
        }}
      />
    </Toolbar>
  );
};
