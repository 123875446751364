import * as React from "react";
import {
  ArrayField,
  DateField,
  ReferenceField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  FunctionField,
  usePermissions,
  useShowController,
} from "react-admin";
import { ScoreShow } from "../scores/score-show";
import { isInternalAdmin } from "../../auth-provider";
import SimpleChipField from "../common/utils";
import { useEffect, useState } from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";

export const CandidateShow = ({ ...props }) => {
  const [candidateUrl, setCandidateUrl] = useState({ url: String });
  const permissions = usePermissions();
  const { record } = useShowController();

  useEffect(() => {
    let didCancel = false;
    if (record) {
      (async () => {
        const urlResponse = await ApiClient.fetch(
          API.CANDIDATES.GET_URL(record.id)
        );

        if (!didCancel) {
          if (urlResponse.status === 200) {
            setCandidateUrl(urlResponse.json.url);
          }
        }
      })();
    }

    return () => {
      didCancel = true;
    };
  }, [record]);

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="id" />
          <TextField source="externalCorrelationId" />
          <TextField source="email" />
          <TextField source="name" />
          <TextField source="lastName" />
          <ReferenceField source="campaignId" reference="campaigns" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="createdBy"
            reference="users"
            link={isInternalAdmin(permissions.permissions) ? "show" : false}
          >
            <TextField source="firstName" />
          </ReferenceField>
          <TextField source="profileId" />
          <ArrayField source="tags">
            <SingleFieldList linkType={false}>
              <SimpleChipField />
            </SingleFieldList>
          </ArrayField>
          <DateField source="startDateTime" />
          <DateField source="endDateTime" />
          <FunctionField
            label="Url"
            source="id"
            render={(record) =>
              record?.id && (
                <a
                  href={candidateUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Candidate
                </a>
              )
            }
          />
        </Tab>
        <Tab label="Scores">
          <ReferenceField
            source="candidateResultsId"
            reference="scores"
            link={false}
            label={false}
          >
            <ScoreShow />
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
