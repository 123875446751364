import * as React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import SkillEditorToolbar from "./SkillEditorToolbar";

export const SkillCreate = (props) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm toolbar={<SkillEditorToolbar />}>
        <TextInput source="name" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
