import { GET_LIST, GET_MANY_REFERENCE, GET_ONE } from "react-admin";
import { GET_SHORTLISTS, GET_SHORTLIST } from "./shortlist-search-query";

// TODO: maybe create a generalGraphqlProvider
const initShortlistsProvider = (client) => {
  return (type, resource, params) => {
    switch (type) {
      case GET_MANY_REFERENCE:
        return client
          .query({
            query: GET_SHORTLISTS,
            variables: {
              page: params.pagination.page - 1,
              size: params.pagination.perPage,
              direction: params.sort.order,
              sort: params.sort.field,
              q: params?.filter?.q || "",
              organisationId: params.id
            },
          })
          .then((response) => ({
            data: response.data.searchShortlists.content,
            total: response.data.searchShortlists.pagination.totalElements
          }));
      case GET_ONE:
        return client
        .query({
          query: GET_SHORTLIST,
          variables: {
            id: params.id
          },
        })
        .then((response) => {
          return ({
          data: response.data.searchShortlist
        })}); 
      case GET_LIST:
      default:
        return client
        .query({
          query: GET_SHORTLISTS,
          variables: {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            direction: params.sort.order,
            sort: params.sort.field,
            q: params?.filter?.q || ""
          },
        })
        .then((response) => ({
          data: response.data.searchShortlists.content,
          total: response.data.searchShortlists.pagination.totalElements
        }));
    }
  };
};

export default initShortlistsProvider;