import * as React from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  SearchInput,
  TextField,
  ListActions,
  usePermissions,
} from "react-admin";
import { isInternalAdmin, readOnly } from "../../auth-provider";

const NameFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="ids" alwaysOn />
  </Filter>
);

export const CompetencyList = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "name", order: "ASC" }}
      actions={isInternalAdmin(permissions.permissions) ? <ListActions /> : null}
      filters={<NameFilter />}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="instrumentId" label="Aon Id" />
        {!readOnly(permissions.permissions) && <EditButton />}
      </Datagrid>
    </List>
  );
};
