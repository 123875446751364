import * as React from "react";
import {
  Show,
  Tab,
  TextField,
  TabbedShowLayout,
  SingleFieldList,
  ArrayField,
  FunctionField,
  ChipField,
  Datagrid,
  ReferenceArrayField,
  ReferenceField,
  EmailField,
  ReferenceManyField,
  Pagination,
  DateField,
  ShowButton,
  DeleteButton,
  ListActions,
  usePermissions,
} from "react-admin";

import SimpleChipField from "../common/utils";
import DownloadCV from "../candidate-pollination/download-cv-button";
import { isInternalAdmin, readOnly } from "../../auth-provider";

const SubReference = ({ translateChoice, children, ...props }) => (
  <ReferenceField {...props}>{children}</ReferenceField>
);

export const ShortlistsShow = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="name" />
          <ReferenceField
            source="organisationId"
            reference="organisations"
            label="Organisation"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="createdBy.id"
            label="Created By"
            reference="users"
            link={isInternalAdmin(permissions.permissions) ? "show" : false}
          >
            <TextField source="firstName" />
          </ReferenceField>
        </Tab>
        <Tab label="Search Parameters">
          <TextField source="searchParameters.seniority" label="seniority" />
          <TextField
            source="searchParameters.careerStage"
            label="Career Stage"
          />
          <FunctionField
            label="Minimum Salary"
            source="searchParameters.minimumSalary.amount"
            render={(record) =>
              record.searchParameters.minimumSalary?.amount &&
              `${record.searchParameters.minimumSalary.currency} ${record.searchParameters.minimumSalary.amount} `
            }
          />
          <FunctionField
            label="Maximum Salary"
            source="searchParameters.maximumSalary.amount"
            render={(record) =>
              record.searchParameters.maximumSalary?.amount &&
              `${record.searchParameters.maximumSalary.currency} ${record.searchParameters.maximumSalary.amount} `
            }
          />
          <ArrayField
            source="searchParameters.workLocations"
            label="Work Locations"
            sortable={false}
          >
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField
            source="searchParameters.skills"
            label="Skills"
            sortable={false}
          >
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField
            source="searchParameters.roles"
            label="Roles"
            sortable={false}
          >
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
        </Tab>
        <Tab label="Candidate Pollination">
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            sort={{ field: "created", order: "DESC" }}
            label={false}
            source="id"
            reference="candidate-pollination"
            target="shortlistId"
          >
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Name"
                link={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Last Name"
                link={false}
              >
                <TextField source="lastName" />
              </ReferenceField>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Email"
                link={false}
              >
                <EmailField source="email" />
              </ReferenceField>
              <DateField source="created" label="Added" />
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Location"
                link={false}
              >
                <SubReference
                  source="profileId"
                  reference="profiles"
                  link={false}
                >
                  <TextField source="location.name" />
                </SubReference>
              </ReferenceField>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Role"
                link={false}
              >
                <SubReference
                  source="profileId"
                  reference="profiles"
                  link={false}
                >
                  <SubReference
                    source="roleId"
                    reference="occupations"
                    link={false}
                  >
                    <TextField source="name" />
                  </SubReference>
                </SubReference>
              </ReferenceField>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Seniority"
                link={false}
              >
                <SubReference
                  source="profileId"
                  reference="profiles"
                  link={false}
                >
                  <TextField source="yearsOfExperience" />
                </SubReference>
              </ReferenceField>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Industry"
                link={false}
              >
                <SubReference
                  source="profileId"
                  reference="profiles"
                  link={false}
                >
                  <TextField source="industry" />
                </SubReference>
              </ReferenceField>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Career Stage"
                link={false}
              >
                <SubReference
                  source="profileId"
                  reference="profiles"
                  link={false}
                >
                  <TextField source="careerStage" />
                </SubReference>
              </ReferenceField>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Current Salary"
                link={false}
              >
                <SubReference
                  source="profileId"
                  reference="profiles"
                  link={false}
                >
                  <FunctionField
                    label="Current Salary"
                    source="annualSalary.amount"
                    render={(record) =>
                      record.annualSalary?.amount &&
                      `${record.annualSalary.currency} ${record.annualSalary.amount} `
                    }
                  />
                </SubReference>
              </ReferenceField>
              <FunctionField
                label="Desired Salary"
                source="desiredSalary.amount"
                render={(record) =>
                  record.desiredSalary?.amount &&
                  `${record.desiredSalary.currency} ${record.desiredSalary.amount} `
                }
              />
              <ArrayField
                source="workLocations"
                label="Work Locations"
                sortable={false}
              >
                <SingleFieldList linkType={false}>
                  <ChipField source="location.name" />
                </SingleFieldList>
              </ArrayField>
              <ReferenceArrayField
                sortBy={"false"}
                label="Skills"
                reference="skills"
                source="skillIds"
              >
                <SingleFieldList linkType={false}>
                  <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
              <ArrayField source="jobModality" label="Job Modality">
                <SingleFieldList linkType={false}>
                  <SimpleChipField />
                </SingleFieldList>
              </ArrayField>
              <ArrayField source="languages">
                <SingleFieldList linkType={false}>
                  <SimpleChipField />
                </SingleFieldList>
              </ArrayField>
              <ShowButton />
              <DownloadCV />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Hired Candidate Pollination">
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            sort={{ field: "created", order: "DESC" }}
            label={false}
            source="id"
            reference="candidate-pollination-hired"
            target="shortlistId"
            actions={isInternalAdmin(permissions.permissions) ? <ListActions /> : null}
          >
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                source="candidatePollinationId"
                reference="candidate-pollination"
                label="Name"
                link={false}
              >
                <SubReference
                  source="candidateId"
                  reference="candidates"
                  link={false}
                >
                  <TextField source="name" />
                </SubReference>
              </ReferenceField>
              <ReferenceField
                source="candidatePollinationId"
                reference="candidate-pollination"
                label="Last Name"
                link={false}
              >
                <SubReference
                  source="candidateId"
                  reference="candidates"
                  link={false}
                >
                  <TextField source="lastName" />
                </SubReference>
              </ReferenceField>
              <ReferenceField
                source="candidatePollinationId"
                reference="candidate-pollination"
                label="Email"
              >
                <SubReference source="candidateId" reference="candidates">
                  <EmailField source="email" />
                </SubReference>
              </ReferenceField>
              <DateField source="created" label="Hired On" />
              <ReferenceField
                source="createdBy"
                reference="users"
                link={isInternalAdmin(permissions.permissions) ? "show" : false}
                label="Hired By"
                emptyText={"System"}
              >
                <TextField source="firstName" />
              </ReferenceField>
              <ReferenceField
                source="shortlistId"
                reference="shortlists"
                label="Organisation"
              >
                <SubReference source="organisationId" reference="organisations">
                  <EmailField source="name" />
                </SubReference>
              </ReferenceField>
              <FunctionField
                label="Salary"
                source="salary.amount"
                render={(record) =>
                  record.salary?.amount &&
                  `${record.salary.currency} ${record.salary.amount} `
                }
              />
              {!readOnly(permissions.permissions) && <DeleteButton />}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
