import {
  ArrayInput,
  Create,
  DeleteButton,
  Edit,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useRedirect,
  useRecordContext
} from "react-admin";
import * as React from "react";
import { useFormState } from "react-hook-form";
import { useDepartments } from "../../../hooks/assessments.questions/useDepartments";
import ApiClient from "../../../ApiClient";
import API from "../../../Routes";
import { useLocation } from "react-router";

export const QuestionsEditor = (props) => {
  const location = useLocation();
  const isCreateView = location?.pathname.includes("create");

  return isCreateView ? (
    <Create {...props}>
      <QuestionsEditorForm />
    </Create>
  ) : (
    <Edit {...props}>
      <QuestionsEditorForm />
    </Edit>
  );
};

const QuestionsEditorForm = (props) => {
  const departments = useDepartments();
  const seniorities = [
    { seniority: "JUNIOR" },
    { seniority: "MID" },
    { seniority: "SENIOR" },
  ];

  return (
    <SimpleForm {...props} toolbar={<QuestionsEditorToolbar />}>
      <SelectInput
        source="department"
        choices={departments}
        optionText="department"
        optionValue="department"
        validate={[required()]}
      />
      <SelectInput
        source="seniority"
        choices={seniorities}
        optionText="seniority"
        optionValue="seniority"
        validate={[required()]}
      />
      <ArrayInput source="questions">
        <SimpleFormIterator>
          <TextInput
            source="description"
            label="Description"
            multiline
            validate={[required()]}
          />
          <TextInput
            source="hints"
            label="Hints"
            multiline
            fullWidth
            format={(value) => {
              return value?.join("\n");
            }}
            parse={(value) => {
              return value?.split("\n");
            }}
            helperText="Each hint goes in a separate line."
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

const QuestionsEditorToolbar = (props) => {
  const { isValid } = useFormState();
  const redirect = useRedirect();
  const record = useRecordContext();

  const handleDelete = async () => {
    await ApiClient.fetch(API.ASSESSMENTS.QUESTIONS.DELETE(record.id), {
      method: "DELETE",
    });
    redirect(`/assessments/${record.assessmentId}/show/1`);
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={!isValid}
        mutationOptions={{
          onSuccess: () =>
            redirect(`/assessments/${record.assessmentId}/show/1`),
        }}
      />
      <DeleteButton mutationOptions={{ onSuccess: handleDelete }} />
    </Toolbar>
  );
};
