import * as React from "react";
import { AVA_ADMIN_URL } from "../../Routes";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Link,
} from "@mui/material";
import { useRecordContext } from "react-admin";

export const ScoreShow = () => {
  const record = useRecordContext();
  
  return (
    <Box margin={1}>
      <Typography variant="h6" gutterBottom component="div">
        Score Breakdown
      </Typography>
      <Table size="small">
        <TableBody>
          {record.assessmentResults.map((assessment) => (
            <TableRow key={assessment.name}>
              <TableCell align="left">{assessment.name}</TableCell>
              <TableCell align="left">{assessment.score}</TableCell>
              <TableCell align="left">
                {assessment.type === "ABILITY"
                  ? getAbilityScoreExplanations(assessment.score)
                  : getPersonalityScoreExplanations(assessment.score)}
              </TableCell>
              <TableCell align="left">{assessment.type}</TableCell>
              <TableCell align="left">-</TableCell>
              <TableCell align="right">
                {assessment?.avaRespondentId ? (
                  <Link
                    href={`${AVA_ADMIN_URL}/respondents/show/${assessment.avaRespondentId}`}
                    target="_blank"
                  >
                    AVA admin
                  </Link>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
          ))}
          {record?.skillAssessmentResults?.length > 0
            ? record.skillAssessmentResults.map((assessment) => (
                <TableRow key={assessment.name}>
                  <TableCell align="left">{assessment.name}</TableCell>
                  <TableCell align="left">{assessment.score}</TableCell>
                  <TableCell align="left">
                    {getAbilityScoreExplanations(assessment.score)}
                  </TableCell>
                  <TableCell align="left">{assessment.type}</TableCell>
                  <TableCell align="left">
                    {assessment.externalAssessmentDifficulty}
                  </TableCell>
                  <TableCell align="right">
                    <Link
                      href={`${AVA_ADMIN_URL}/respondents/show/${assessment.avaRespondentId}`}
                      target="_blank"
                    >
                      AVA admin
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Box>
  );
};

const getAbilityScoreExplanations = (points) => {
  if (points >= 90) {
    return "well above average";
  }

  if (points >= 70) {
    return "above average";
  }

  if (points >= 31) {
    return "average";
  }

  if (points >= 11) {
    return "below average";
  }

  return points ? "well below average" : "awaiting data";
};

const getPersonalityScoreExplanations = (points) => {
  if (points >= 80) {
    return "very strong alignment";
  }

  if (points >= 60) {
    return "strong alignment";
  }

  if (points >= 50) {
    return "moderate alignment";
  }

  if (points >= 30) {
    return "some risk - explore further";
  }

  return points ? "risk - explore further" : "awaiting data";
};
