import decodeJwt from 'jwt-decode';
import {BASE_PATH} from "./Routes";

export const isSuperAdmin = (role) => {
    return role === 'SUPER_ADMIN';
}

export const isBDM = (role) => {
    return role === 'BDM';
}

export const readOnly = (role) => {
    return isBDM(role)
}

export const isInternalAdmin = (role) => {
    return role === 'SUPER_ADMIN' || role === 'ADMIN';
}

export const isInternal = (role) => {
    return role === 'SUPER_ADMIN' || role === 'ADMIN' || role === 'BDM';
}

const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(`${BASE_PATH}/oauth/token`, {
            method: 'POST',
            body: JSON.stringify({ username, password, grantType: 'password' }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ accessToken }) => {
                const decodedToken = decodeJwt(accessToken);
                localStorage.setItem('token', accessToken);
                localStorage.setItem('role', decodedToken.role);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: (params) => {
        const role = localStorage.getItem('role');
        return isInternal(role) ? Promise.resolve(role) : Promise.reject();
    }
}

export default authProvider;