import {
    TextField,
    List,
    Datagrid,
    Filter,
    SearchInput,
    NumberField,
    ReferenceField,
    DateField,
    BooleanField,
    usePermissions
} from "react-admin";
import * as React from "react";
import {isInternalAdmin} from "../../auth-provider";

const ListFilters = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

export const ShortlistList = ({...props}) => {
    const permissions = usePermissions();

    return (
        <List {...props} filters={<ListFilters/>} perPage={25} sort={{ field: 'updated', order: 'DESC' }}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceField source="organisationId" reference="organisations" label="Organisation" link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="campaignId" label="Recruiter Enabled" reference="campaigns" link={false}>
                <BooleanField source="recruiterEnabled" />
            </ReferenceField>
            <ReferenceField source="createdBy.id" label="Created By" reference="users" link={isInternalAdmin(permissions.permissions) ? "show" :  false}>
                <TextField source="firstName" />
            </ReferenceField>
            <DateField source="updated" />
            <NumberField source="amountOfCandidates" label="Candidates"/>
            <NumberField source="amountOfHiredCandidates" label="Hired Candidates"/>
        </Datagrid>
    </List>
    );
}