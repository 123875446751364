import { useEffect, useState } from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";

export const useCurrencies = () => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    let didCancel = false;

    (async () => {
      const currenciesResponse = await ApiClient.fetch(
        `${API.ORGANISATIONS.GET_CURRENCIES}`
      );

      if (!didCancel) {
        setCurrencies(currenciesResponse.json);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, []);

  return currencies;
};
