import {
  Button,
  Datagrid,
  downloadCSV,
  Filter,
  List,
  ReferenceField,
  sanitizeListRestProps,
  SearchInput,
  TextField,
  TopToolbar,
  useListContext,
  usePermissions,
  useNotify,
} from "react-admin";
import * as React from "react";
import { cloneElement } from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";
import { isSuperAdmin } from "../../auth-provider";
import { FileDownload, Downloading } from "@mui/icons-material";

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  const notify = useNotify();
  const [isLoadingExport, setIsLoadingExport] = React.useState(false);

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <Button
        onClick={async () => {
          try {
            setIsLoadingExport(true);
            const exportResponse = await ApiClient.fetch(API.CANDIDATES.EXPORT);
            const data = exportResponse.body;
            downloadCSV(data, "candidates");
          } catch (error) {
            notify("Error exporting candidates", "error");
            console.error("Error exporting data:", error);
          } finally {
            setIsLoadingExport(false);
          }
        }}
        label={isLoadingExport ? "Exporting" : "Export Candidates"}
        disabled={isLoadingExport}
      >
        {isLoadingExport ? <Downloading /> : <FileDownload />}
      </Button>
    </TopToolbar>
  );
};

const ListFilters = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const CandidateList = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <List
      {...props}
      filters={<ListFilters />}
      actions={isSuperAdmin(permissions.permissions) ? <ListActions /> : null}
      perPage={25}
      sort={{ field: "updated", order: "DESC" }}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="lastName" />
        <TextField source="email" />
        <ReferenceField source="campaignId" reference="campaigns" link="show">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
