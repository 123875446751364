import * as React from "react";
import {
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EmailField,
  FunctionField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useGetMany,
  usePermissions,
  useRecordContext,
} from "react-admin";
import { ScoreShow } from "../scores/score-show";
import { isInternalAdmin } from "../../auth-provider";
import { Stack, Box, Chip, Typography } from "@mui/material";

const STAGE = process.env.REACT_APP_STAGE || "staging";
const CANDIDATE_ENDPOINT =
  STAGE === "production"
    ? "https://app.thrivetech.co/candidates"
    : "https://app.chasana.biz/candidates";

const CampaignShowTitle = () => {
  const record = useRecordContext();
  return <span>Campaign {record ? `"${record.name}"` : ""}</span>;
};

const SkillAssessments = () => {
  const record = useRecordContext();
  const skillIds = record.skillAssessments?.map((skill) => skill.id);
  const { data } = useGetMany("skill-assessments", { ids: skillIds });

  return (
    <Box>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        gutterBottom
        sx={{ fontSize: "12px", marginBottom: "8px" }}
      >
        Skill Assessments
      </Typography>
      <Stack direction="row" spacing={1} label="Skill Assessments">
        {data?.length > 0 ? (
          data.map((skill) => (
            <Chip
              label={`${skill.name} - ${
                record.skillAssessments?.find(({ id }) => id === skill.id)
                  ?.proficiency
              }`}
            />
          ))
        ) : (
          <Typography variant="body2">No skill assessments</Typography>
        )}
      </Stack>
    </Box>
  );
};

const PsychometricAssessments = () => {
  const record = useRecordContext();
  const assessmentIds = record.assessments.map((assessment) => assessment.id);
  const { data } = useGetMany("assessments", { ids: assessmentIds });

  return (
    <Box>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        gutterBottom
        sx={{ fontSize: "12px", marginBottom: "8px" }}
      >
        Psychometric Assessments
      </Typography>
      <Stack direction="row" spacing={1} label="Psychometric Assessments">
        {data?.length > 0 ? (
          data.map((assessment) => <Chip label={assessment.name} />)
        ) : (
          <Typography variant="body2">No psychometric assessments</Typography>
        )}
      </Stack>
    </Box>
  );
};

const CampaignShowActions = (props) => {
  const record = useRecordContext();

  return (
    <TopToolbar {...props}>
      <DeleteWithConfirmButton record={record} />
    </TopToolbar>
  );
};

export const CampaignShow = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <Show
      {...props}
      title={<CampaignShowTitle />}
      actions={
        isInternalAdmin(permissions.permissions) ? (
          <CampaignShowActions />
        ) : null
      }
    >
      <TabbedShowLayout>
        <Tab label="Candidates - Scores">
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            label={false}
            reference="scores"
            //@todo: migrate to campaignIds
            target="campaignId"
            sort={{ field: "overallScore", order: "DESC" }}
          >
            <Datagrid expand={<ScoreShow />} bulkActionButtons={false}>
              <TextField source="candidateId" label="Candidate Id" />
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Email"
                link={false}
              >
                <EmailField source="email" />
              </ReferenceField>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="External Correlation Id"
                link={false}
              >
                <TextField source="externalCorrelationId" />
              </ReferenceField>
              <TextField source="candidateName" label="Name" />
              <TextField source="status" label="Status" />
              <NumberField source="overallScore" />
              <DateField source="sentDate" />
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Expiry Date"
                link={false}
              >
                <DateField source="endDateTime" />
              </ReferenceField>
              <ReferenceField
                source="candidateId"
                reference="candidates"
                label="Candidate Link"
                link={false}
              >
                <FunctionField
                  render={(record) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${CANDIDATE_ENDPOINT}/${record.id}?token=${record.token}`}
                    >
                      Candidate Link
                    </a>
                  )}
                />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Summary">
          <TextField source="id" />
          <TextField source="name" />
          <ReferenceField
            source="organisationId"
            reference="organisations"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="assessmentsSource" />
          <ReferenceField
            source="createdBy"
            reference="users"
            link={isInternalAdmin(permissions.permissions) ? "show" : false}
          >
            <TextField source="firstName" />
          </ReferenceField>
          <DateField source="startDateTime" />
          <DateField source="endDateTime" />
          <NumberField source="amountOfCandidates" />
          <TextField source="occupationName" />
          <TextField source="normType" />
          <TextField source="language" label="Language" />
          <TextField source="timezone" />
          <PsychometricAssessments />
          <SkillAssessments />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
