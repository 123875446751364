import * as React from "react";
import { Button, useRecordContext } from "react-admin";
import ApiClient from "../../ApiClient";
import API from "../../Routes";
import clsx from "clsx";
import { makeStyles, withStyles } from "@mui/styles";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const useStyles = makeStyles((theme) => ({
  disableButton: {
    color: "#f44336",
  },
}));

const DownloadCV = () => {
  const record = useRecordContext();
  const hasCV = !!record.cvUrl;
  const classes = useStyles();

  return (
    <>
      <Button
        variant="contained"
        target="_blank"
        label="Download CV"
        disabled={!hasCV}
        className={clsx(!hasCV && classes.disableButton)}
        onClick={async () => {
          const response = await ApiClient.fetch(
            API.POLLINATION_CANDIDATE.DOWNLOAD_CV(record.id)
          );

          const link = document.createElement("a");
          link.setAttribute("href", response.body); // lo mismo que response.body
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.open(response.body, "_blank");
        }}
      />
    </>
  );
};

export default withStyles(styles)(DownloadCV);
