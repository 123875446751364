import {
  Datagrid,
  List,
  EditButton,
  EmailField,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
  ReferenceField,
  downloadCSV,
  Button,
  usePermissions,
  ShowButton,
} from "react-admin";
import * as React from "react";
import DisableUserButton from "./disable-user-button";
import { cloneElement } from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";
import { isSuperAdmin } from "../../auth-provider";
import { FileDownload } from "@mui/icons-material";

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter, total } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <Button
        disabled={total === 0}
        onClick={async () => {
          const exportResponse = await ApiClient.fetch(
            API.USERS.EXPORT("CUSTOMER")
          );
          const data = exportResponse.body;
          downloadCSV(data, "customer-users");
        }}
        label="Export Customer Users"
      >
        <FileDownload />
      </Button>
    </TopToolbar>
  );
};

export const UserList = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "updated", order: "DESC" }}
      actions={isSuperAdmin(permissions.permissions) ? <ListActions /> : null}
      filter={{userType: 'CUSTOMER'}}
    >
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="role" />
        <ReferenceField
          source="organisationId"
          reference="organisations"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ShowButton />
        {isSuperAdmin(permissions.permissions) && <EditButton />}
        {isSuperAdmin(permissions.permissions) && <DisableUserButton />}
      </Datagrid>
    </List>
  );
};
