import * as React from "react";
import { Link } from "react-router-dom";
import { Button, useRecordContext } from "react-admin";
import { Person } from "@mui/icons-material";
import { withStyles } from "@mui/styles";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddUserButton = ({ classes, internalUsers }) => {
  const record = useRecordContext();
  return (
    <Button
      className={internalUsers ? undefined : classes.button}
      variant={internalUsers ? "text" : "contained"}
      component={Link}
      to={{
        pathname: internalUsers ? "/internal-users/create" : "/users/create",
        state: { record: { organisationId: record?.id } },
      }}
      label="Add User"
    >
      <Person />
    </Button>
  );
};

export default withStyles(styles)(AddUserButton);
