import {
  Edit,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import * as React from "react";
import { useRoles } from "../../hooks/user/useRoles";
import { useFormState } from "react-hook-form";
import { useLocation, useParams } from "react-router";

const UserEditToolbar = (props) => {
  const { isValid } = useFormState();

  return (
    <Toolbar {...props}>
      <SaveButton disabled={!isValid} />
    </Toolbar>
  );
};

export const UserEdit = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const internalUsers = location?.pathname.includes("internal-users");
  const roles = useRoles(internalUsers ? "INTERNAL" : "ORGANISATION_LEVEL");

  return (
    <Edit
      {...props}
      redirect={`${internalUsers ? "/internal-users" : `/users/${id}/show`}`}
    >
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <SelectInput
          source="role"
          choices={roles}
          optionText="role"
          optionValue="role"
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};
