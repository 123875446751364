import {
  DateField,
  NumberField,
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  useRecordContext,
  usePermissions,
} from "react-admin";
import * as React from "react";
import { isInternalAdmin } from "../../auth-provider";
import { FiberManualRecord } from "@mui/icons-material";
import {
  Stack,
  Box,
  Chip,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: { margin: "12px 0" },
  listItemText: { fontSize: "14px", lineHeight: "14px" },
  iconWrapper: { minWidth: "unset", marginRight: "8px" },
  icon: { fontSize: "14px" },
});

const Proficiencies = () => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <Box>
      <Stack direction="row" spacing={1} className={classes.container}>
        {record.proficiencies.map((item) => (
          <Chip label={item} key={item} />
        ))}
      </Stack>
    </Box>
  );
};

const ArrayList = ({ source, title }) => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <Box>
      <List>
        {record[source].map((item) => (
          <ListItem key={item}>
            <ListItemIcon className={classes.iconWrapper}>
              <FiberManualRecord className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={item}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export const SkillAssessmentShow = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />
          <TextField source="externalAssessmentId" />
          <DateField source="created" />
          <DateField source="updated" />
          <ReferenceField
            source="updatedBy"
            reference="users"
            link={isInternalAdmin(permissions.permissions) ? "show" : false}
          >
            <TextField source="firstName" />
          </ReferenceField>
          <TextField source="name" />
          <TextField source="description" />
          <NumberField source="durationInSeconds" />
          <TextField source="status" />
          <TextField source="category" />
          <Proficiencies />
        </Tab>
        <Tab label="details">
          <TextField source="longDescription" />
          <TextField source="about" />
          <TextField source="testScience" />
          <ArrayList source="relevantRoles" title="Relevant roles" />
          <ArrayList source="coveredSkills" title="Covered skills" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
