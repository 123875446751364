import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  SelectInput,
  required,
  SelectArrayInput,
  ReferenceArrayInput,
  BooleanInput,
} from "react-admin";
import { useTimezones } from "../../hooks/organisations/useTimezones";
import { valueArrayToObject } from "../common/utils";
import { PRODUCTS } from "./organisation-products";
import { useCurrencies } from "../../hooks/organisations/useCurrencies";

const OrganisationEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const OrganisationEdit = (props) => {
  const timezones = useTimezones();
  const currencies = useCurrencies();

  return (
    <Edit {...props} redirect='show'>
      <SimpleForm toolbar={<OrganisationEditToolbar />}>
        <TextInput source="name" validate={[required()]} />
        <SelectInput
          source="timezone"
          choices={timezones}
          optionText="timezone"
          optionValue="timezone"
          validate={[required()]}
        />
        <ReferenceArrayInput
          label="BDM users"
          source="bdmIds"
          reference="internal-users"
          perPage={100}
          filter={{ userType: "INTERNAL" }}
        >
          <SelectArrayInput source="id" optionText="email" />
        </ReferenceArrayInput>
        <SelectArrayInput
          source="products"
          choices={valueArrayToObject(PRODUCTS)}
          validate={[required()]}
        />
        <BooleanInput source="openForPollination" validate={[required()]} />
        <TextInput
          label="Billing Data Email"
          source="billingData.email"
          validate={[required()]}
        />
        <SelectInput
          source="billingData.currency"
          choices={valueArrayToObject(currencies)}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};
