import { Edit, required, SimpleForm, TextInput } from "react-admin";
import * as React from "react";

export const AssessmentEdit = (props) => {
  return (
    <Edit {...props} redirect="list">
      <SimpleForm>
        <TextInput source="name" disabled />
        <TextInput source="description" validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
};
