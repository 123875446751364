import * as React from "react";
import {
  Show,
  Tab,
  TextField,
  TabbedShowLayout,
  ReferenceField,
  EmailField,
  DateField,
  SingleFieldList,
  ArrayField,
  Datagrid,
  UrlField,
  BooleanField,
  FunctionField,
  ChipField,
  NumberField,
} from "react-admin";
import { ScoreShow } from "../scores/score-show";
import SimpleChipField from "../common/utils";
import DownloadCvButton from "./download-cv-button";

const SubReference = ({ translateChoice, children, ...props }) => (
  <ReferenceField {...props}>{children}</ReferenceField>
);

export const CandidatePollinationShow = ({ ...props }) => {

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <DateField source="created" label="Created" />
          <ArrayField source="jobModality" label="Job Modality">
            <SingleFieldList linkType={false}>
              <SimpleChipField />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="skills" label="Skills">
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="languages" label="Languages">
            <SingleFieldList linkType={false}>
              <SimpleChipField />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="workLocations" label="Work Locations">
            <Datagrid bulkActionButtons={false}>
              <BooleanField source="rightToWork" label="Right to Work" />
              <TextField source="location.name" label="Location" />
            </Datagrid>
          </ArrayField>
          <FunctionField
            label="Desired Salary"
            render={(record) =>
              `${record.desiredSalary.currency} ${record.desiredSalary.amount} `
            }
          />
          <UrlField source="externalLinks.linkedIn" label="External Links" />
          <DownloadCvButton />
        </Tab>
        <Tab label="Profile">
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="First Name"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="name" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Last Name"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="lastName" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Email"
          >
            <SubReference source="profileId" reference="profiles">
              <EmailField source="email" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Mobile Number"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="mobileNumber" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Gender"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="gender" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Ethnicity"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="ethnicity" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Nationality"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="nationality" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Industry"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="industry" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Role"
          >
            <SubReference source="profileId" reference="profiles">
              <SubReference source="roleId" reference="occupations">
                <TextField source="name" />
              </SubReference>
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Is Current Role"
          >
            <SubReference source="profileId" reference="profiles">
              <BooleanField source="currentRole" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Current Company"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="currentCompany" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Career Stage"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="careerStage" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Work Status"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="workStatus" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Job Function"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="jobFunction" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Seniority"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="yearsOfExperience" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Date Of Birth"
          >
            <SubReference source="profileId" reference="profiles">
              <DateField source="dateOfBirth" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Organization"
          >
            <SubReference source="campaignId" reference="campaigns">
              <SubReference source="organisationId" reference="organisations">
                <TextField source="name" />
              </SubReference>
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label="Location"
          >
            <SubReference source="profileId" reference="profiles">
              <TextField source="location.name" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link="show"
            label="Overall Score"
          >
            <SubReference source="candidateResultsId" reference="scores">
              <NumberField source="overallScore" />
            </SubReference>
          </ReferenceField>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            label="Completed Date"
          >
            <SubReference source="candidateResultsId" reference="scores">
              <DateField source="completedDateTime" />
            </SubReference>
          </ReferenceField>
        </Tab>
        <Tab label="Scores" defaultChecked={true}>
          <ReferenceField
            source="candidateId"
            reference="candidates"
            link={false}
            label={false}
          >
            <SubReference source="candidateResultsId" reference="scores">
              <ScoreShow />
            </SubReference>
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
