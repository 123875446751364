import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SearchInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DeleteButton,
  EditButton,
  ListActions,
  usePermissions,
} from "react-admin";
import { isInternalAdmin, readOnly } from "../../auth-provider";

const NameFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const OccupationList = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "name", order: "ASC" }}
      filters={<NameFilter />}
      actions={isInternalAdmin(permissions.permissions) ? <ListActions /> : null}
    >
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceArrayField
          sort={{ field: "name", order: "ASC" }}
          sortBy={"false"}
          label="Assessments"
          reference="assessments"
          source="recommendedAssessmentIds"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="normType" />
        <TextField source="department" />
        {!readOnly(permissions.permissions) && <EditButton />}
        {!readOnly(permissions.permissions) && <DeleteButton />}
      </Datagrid>
    </List>
  );
};
