import { fetchUtils, GET_LIST } from "react-admin";

const initCandidatePollinationHiredProvider = (
  apiUrl,
  httpClient = fetchUtils.fetchJson,
  defaultCrudHandler,
  convertDataRequestToHTTP
) => {
  return (type, resource, params) => {
    switch (type) {
      case GET_LIST:
        return defaultCrudHandler(type, `${resource}`, params);
      default:
        return defaultCrudHandler(type, resource, params);
    }
  };
};

export default initCandidatePollinationHiredProvider;