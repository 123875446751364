import {CREATE, DELETE, fetchUtils, GET_MANY_REFERENCE, GET_ONE, UPDATE} from "react-admin";
import API from "../../Routes";

const initImprovementsProvider = (apiUrl, httpClient = fetchUtils.fetchJson, defaultCrudHandler) => {
    return (type, resource, params) => {
        switch (type) {
            case GET_ONE:
                return defaultCrudHandler(type, "assessments/improvements", params);
            case CREATE:
                return defaultCrudHandler(type, `assessments/${params.data.assessmentId}/improvements`, params);
            case UPDATE:
                return defaultCrudHandler(type, "assessments/improvements", params)
            case DELETE:
                return defaultCrudHandler(type, "assessments/improvements", params);
            case GET_MANY_REFERENCE:
                return httpClient(API.ASSESSMENTS.IMPROVEMENTS.FIND(params.id, params)).then(response => ({
                    data: response.json.content,
                    total: parseInt(response.json.totalElements, 10)
                }));
            default:
                return defaultCrudHandler(type, resource, params);
        }
    }
};

export default initImprovementsProvider;