import * as React from "react";
import { isInternalAdmin } from "../../auth-provider";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  useNotify,
  Button,
  Confirm,
  useListContext,
  ListActions,
  SearchInput,
  useRecordContext,
  usePermissions,
  ShowButton
} from "react-admin";
import { useState } from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";
import clsx from "clsx";
import { DoubleArrow, NotInterested } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const IdFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="ids" alwaysOn placeholder="Search Id" />
  </Filter>
);

export const SkillAssessmentList = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "name", order: "ASC" }}
      actions={isInternalAdmin(permissions.permissions) ? <ListActions /> : null}
      filter={{ status: "ACTIVE,DISABLED" }}
      filters={<IdFilter />}
    >
      <Datagrid rowClick={false} bulkActionButtons={false} >
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField
          source="externalAssessmentId"
          label="External Assessment Id"
        />
        <ShowButton />
        {isInternalAdmin(permissions.permissions) && <DisableSkillAssessmentButton />}
      </Datagrid>
    </List>
  );
};

const useStyles = makeStyles(() => ({
  disableButton: {
    color: "#f44336",
  },
}));

const DisableSkillAssessmentButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const { refetch } = useListContext();
  const notify = useNotify();
  const isActive = record.status === "ACTIVE";
  const classes = useStyles();

  const toggleSkillAssessmentStatus = async () => {
    setOpen(false);
    try {
      const { status } = await ApiClient.fetch(
        API.SKILL_ASSESSMENTS.STATUS(record.id),
        {
          method: "PATCH",
          body: { status: isActive ? "DISABLED" : "ACTIVE" },
        }
      );
      if (status === 200) {
        notify(`Skill assessment ${isActive ? "disabled" : "enabled"}.`);
        refetch();
      }
    } catch (error) {
      console.error(error.message);
      notify(`Failed to ${isActive ? "disable" : "enable"} skill assessment.`);
    }
  };

  return (
    <>
      <Button
        label={isActive ? "Disable" : "Enable"}
        className={clsx(isActive && classes.disableButton)}
        startIcon={isActive ? <NotInterested /> : <DoubleArrow />}
        onClick={() => setOpen(true)}
      />
      <Confirm
        isOpen={open}
        title={(isActive ? "Disable" : "Enable") + " Assessment"}
        content={`Are you sure you want to ${
          isActive ? "disable" : "enable"
        } skill assessment ${record.name}?`}
        onConfirm={toggleSkillAssessmentStatus}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
