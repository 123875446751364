import {
  CREATE,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from "react-admin";

const initInternalUsersProvider = (
  apiUrl,
  httpClient = fetchUtils.fetchJson,
  defaultCrudHandler
) => {
  return (type, resource, params) => {
    switch (type) {
      case GET_LIST:
        return defaultCrudHandler(type, "users", params);
      case CREATE:
        return defaultCrudHandler(type, "users/internal", params);
      case GET_ONE:
        return defaultCrudHandler(type, "users", params);
      case UPDATE:
        return defaultCrudHandler(type, "users/internal", params);
      case GET_MANY:
        return defaultCrudHandler(type, "users", params);
      default:
        return defaultCrudHandler(type, resource, params);
    }
  };
};

export default initInternalUsersProvider;
