import { useEffect, useState } from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";

export const useOccupationsOnetCodes = () => {
  const [onetCodes, setOnetCodes] = useState([]);
  const [isOnetLoading, setIsOnetloading] = useState(false);

  useEffect(() => {
    let didCancel = false;

    (async () => {
      setIsOnetloading(true);
      const onetCodesResponses = await ApiClient.fetch(
        `${API.OCCUPATIONS.GET_ONETCODES}`
      );

      if (!didCancel) {
        setOnetCodes(
          onetCodesResponses.json.map((onetCode) => ({
            id: onetCode.onetCode,
            name: onetCode.title,
          }))
        );
      }
      setIsOnetloading(false);
    })();

    return () => {
      didCancel = true;
    };
  }, []);

  return { onetCodes, isOnetLoading };
};
