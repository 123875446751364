import {CREATE, DELETE, fetchUtils, GET_MANY_REFERENCE, GET_ONE, UPDATE} from "react-admin";
import API from "../../Routes";

const initQuestionsProvider = (apiUrl, httpClient = fetchUtils.fetchJson, defaultCrudHandler) => {
    return (type, resource, params) => {
        switch (type) {
            case GET_ONE:
                return defaultCrudHandler(type, "assessments/questions", params);
            case CREATE:
                params = filterNotBlankHints(params);
                return defaultCrudHandler(type, `assessments/${params.data.assessmentId}/questions`, params);
            case UPDATE:
                params = filterNotBlankHints(params);
                return defaultCrudHandler(type, "assessments/questions", params)
            case DELETE:
                return defaultCrudHandler(type, "assessments/questions", params);
            case GET_MANY_REFERENCE:
                return httpClient(API.ASSESSMENTS.QUESTIONS.FIND(params.id, params)).then(response => ({
                    data: response.json.content,
                    total: parseInt(response.json.totalElements, 10)
                }));
            default:
                return defaultCrudHandler(type, resource, params);
        }
    }
};

const filterNotBlankHints = (params) => {
    if (params.data.questions) {
        params.data.questions = params.data.questions.map(question => {
            question.hints = question.hints.filter(h => !(!h || /^\s*$/.test(h)));
            return question;
        });
    }

    return params;
};

export default initQuestionsProvider;