import {useEffect, useState} from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";

export const useDepartments = () => {
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        let didCancel = false;

        (async () => {
            const departmentsResponse = await ApiClient.fetch(`${API.OCCUPATIONS.GET_DEPARTMENTS}`);

            if (!didCancel) {
                setDepartments(departmentsResponse.json.map(d => ({department: d})));
            }

        })();

        return () => {
            didCancel = true;
        }
    }, []);

    return departments;
};