import { gql } from "@apollo/client";

export const GET_SHORTLISTS = gql`
  query searchShortlists(
    $q: String!
    $page: Int!
    $size: Int!
    $sort: String!
    $direction: String!
    $organisationId: String
  ) {
    searchShortlists(
      searchParameters: { organisationId: $organisationId, q: $q }
      pageableRequest: {
        page: $page
        size: $size
        sort: $sort
        direction: $direction
      }
    ) {
      content {
        id
        created
        createdBy {
          id
          firstName
          lastName
        }
        updated
        name
        organisationId
        campaignId
        searchParameters {
          roles {
            id
            name
            department
          }
          skills {
            id
            name
          }
          roleIds
          skillIds
          seniority
          careerStage
          minimumSalary {
            currency
            amount
          }
          maximumSalary {
            currency
            amount
          }
          workLocations {
            lng
            lat
            name
            googlePlaceId
          }
          jobModality
          includeRoleDepartments
        }
        timezone
        amountOfCandidates
        amountOfHiredCandidates
      }
      pagination {
        totalElements
        totalPages
      }
    }
  }
`;

export const GET_SHORTLIST = gql`
  query searchShortlist(
    $id: String
  ) {
    searchShortlist(
      id: $id
    ) {
        id
        created
        createdBy {
          id
          firstName
          lastName
        }
        updated
        name
        organisationId
        campaignId
        searchParameters {
          roles {
            id
            name
            department
          }
          skills {
            id
            name
          }
          roleIds
          skillIds
          seniority
          careerStage
          minimumSalary {
            currency
            amount
          }
          maximumSalary {
            currency
            amount
          }
          workLocations {
            lng
            lat
            name
            googlePlaceId
          }
          jobModality
          includeRoleDepartments
        }
        timezone
        amountOfCandidates
        amountOfHiredCandidates
    }
  }
`;