import {useEffect, useState} from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";

export const useTimezones = () => {
    const [timezones, setTimezones] = useState([])

    useEffect(() => {
        let didCancel = false;

        (async () => {
            const timezonesResponse = await ApiClient.fetch(`${API.ORGANISATIONS.GET_TIMEZONES}`);

            if (!didCancel) {
                setTimezones(timezonesResponse.json.content.map(t => ({timezone: t})));
            }

        })();

        return () => {
            didCancel = true;
        }
    }, []);

    return timezones;
}