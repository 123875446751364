import initGeneralCrudProvider from "./general-crud/general-crud-provider";
import initInternalUsersProvider from "./users/internal/internal-users-provider";
import initCustomerUsersProvider from "./users/customer/customer-users-provider";
import initQuestionsProvider from "./questions/questions-provider";
import initImprovementsProvider from "./improvements/improvements-provider";
import initOrganisationsProvider from "./organisations/organisations-provider";
import initPollinationCandidatesProvider from "./pollination-candidates/pollination-candidates-provider";
import initShortlistsProvider from "./shortlists/shortlists-provider";
import initCandidatePollinationHiredProvider from "./candidate-pollination-hired/candidate-pollination-hired-provider";
import initSkillsProvider from "./skills/skills-provider";
import initResultsProvider from "./results/results-provider";
import initProjectTeamsProvider from "./project-teams/project-teams-provider";

const getDataProvider = (apiUrl, clients) => {

  const generalCrudProvider = initGeneralCrudProvider(apiUrl, clients.httpClient);
  const internalUsersProvider = initInternalUsersProvider(apiUrl, clients.httpClient, generalCrudProvider);
  const customerUsersProvider = initCustomerUsersProvider(apiUrl, clients.httpClient, generalCrudProvider);
  const questionsProvider = initQuestionsProvider(apiUrl, clients.httpClient, generalCrudProvider);
  const improvementsProvider = initImprovementsProvider(apiUrl, clients.httpClient, generalCrudProvider);
  const organisationsProvider = initOrganisationsProvider(apiUrl, clients.httpClient, generalCrudProvider);
  const pollinationCandidatesProvider = initPollinationCandidatesProvider(apiUrl, clients.httpClient, generalCrudProvider);
  const shortlistsProvider = initShortlistsProvider(clients.apolloClient);
  const candidatePollinationHiredProvider = initCandidatePollinationHiredProvider(apiUrl, clients.httpClient, generalCrudProvider);
  const skillsProvider = initSkillsProvider(apiUrl, clients.httpClient, generalCrudProvider);
  const resultsProvider = initResultsProvider(apiUrl, clients.httpClient, generalCrudProvider);
  const projectTeamsProvider = initProjectTeamsProvider(apiUrl, clients.httpClient, generalCrudProvider);

  /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
  return (type, resource, params) => {
    switch (resource) {
      case 'internal-users':
        return internalUsersProvider(type, resource, params);
      case 'users':
        return customerUsersProvider(type, resource, params);
      case 'questions':
        return questionsProvider(type, resource, params);
      case 'improvements':
        return improvementsProvider(type, resource, params);
      case 'organisations':
        return organisationsProvider(type, resource, params);
      case 'candidate-pollination':
        return pollinationCandidatesProvider(type, resource, params);
      case 'shortlists':
        return shortlistsProvider(type, resource, params);
      case 'candidate-pollination-hired':
        return candidatePollinationHiredProvider(type, resource, params);
      case 'skills':
        return skillsProvider(type, resource, params);
      case 'results':
        return resultsProvider(type, resource, params);
      case 'projectTeams':
        return projectTeamsProvider(type, resource, params);
      default:
        return generalCrudProvider(type, resource, params);
    }
  };
};

export default getDataProvider;