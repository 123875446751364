import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SearchInput,
  ListActions,
  DateField,
  ReferenceField,
  usePermissions,
} from "react-admin";
import { isInternalAdmin } from "../../auth-provider";

const NameFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const SkillsList = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "created", order: "DESC" }}
      filters={<NameFilter />}
      actions={isInternalAdmin(permissions.permissions) ? <ListActions /> : null}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField
          source="createdBy"
          reference="users"
          link={isInternalAdmin(permissions.permissions) ? "show" : false}
          emptyText={"System"}
        >
          <TextField source="firstName" />
        </ReferenceField>
        <DateField source="created" />
      </Datagrid>
    </List>
  );
};
