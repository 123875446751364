import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  ListActions,
  usePermissions,
} from "react-admin";
import { isInternalAdmin } from "../../auth-provider";

export const ProjectList = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: "updated", order: "DESC" }}
      actions={isInternalAdmin(permissions.permissions) ? <ListActions /> : null}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField
          source="createdBy"
          reference="users"
          link={isInternalAdmin(permissions.permissions) ? "show" : false}
        >
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField
          source="organisationId"
          reference="organisations"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <NumberField
          source="amountOfCompletedEmployees"
          label="Completed Employees"
        />
        <NumberField source="amountOfEmployees" label="Total Employees" />
        <TextField source="assessmentsSource" />
        <DateField source="startDateTime" />
        <DateField source="updated" />
        <TextField source="language" />
      </Datagrid>
    </List>
  );
};
