import { SaveButton, Toolbar } from "react-admin";
import * as React from "react";
import { useFormState } from "react-hook-form";

const OccupationEditorToolbar = (props) => {
  const { isValid } = useFormState();

  return (
    <Toolbar {...props}>
      <SaveButton disabled={!isValid} />
    </Toolbar>
  );
};

export default OccupationEditorToolbar;
