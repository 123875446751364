import {fetchUtils} from "react-admin";

export default class ApiClient {

    static fetch = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            });
        }

        if (options.body && !isJson(options.body)) {
            options.body = JSON.stringify(options.body);
        }

        return fetchUtils.fetchJson(url, options);
    };
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}