import {useEffect, useState} from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";

export const useRoles = (roleTypes = "ALL") => {
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        let didCancel = false;

        (async() => {
            const rolesResponse = await ApiClient.fetch(`${API.ROLES}?roleTypes=${roleTypes}`);

            if (!didCancel) {
                setRoles(rolesResponse.json.content);
            }

        })();

        return () => {
            didCancel = true;
        }
    }, [roleTypes]);

    return roles;
};