import {
  Datagrid,
  DateField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Tab,
  TextField,
  Show,
  TabbedShowLayout,
  EditButton,
  usePermissions,
  useRecordContext
} from "react-admin";
import * as React from "react";
import AddQuestionsButton from "./questions/add-questions-button";
import AddImprovementsButton from "./improvements/add-improvements-button";
import { isInternalAdmin, readOnly } from "../../auth-provider";
import { Typography, Table, TableBody, TableRow, TableCell, Box, Collapse, Grid, IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";

export const AssessmentShow = ({ ...props }) => {
  const permissions = usePermissions();
  
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="id" />
          <DateField source="created" />
          <DateField source="updated" />
          <TextField source="type" />
          <TextField source="name" />
          <TextField source="assessment" />
          <TextField source="description" />
          <NumberField source="durationInSeconds" />
        </Tab>
        <Tab label="Questions">
          {!readOnly(permissions.permissions) && (
            <Grid container justify={"flex-end"}>
              <AddQuestionsButton />
            </Grid>
          )}
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            label={false}
            reference="questions"
            target="id"
            sort={{ field: "department", order: "ASC" }}
          >
            <Datagrid expand={<QuestionsShow />} rowClick="expand" bulkActionButtons={false}>
              <TextField source="id" label="Questions Id" />
              <ReferenceField
                source="createdBy"
                reference="users"
                link={isInternalAdmin(permissions.permissions) ? "show" : false}
                emptyText={"System"}
                textAlign={"center"}
              >
                <TextField source="firstName" />
              </ReferenceField>
              <DateField source="created" />
              <DateField source="updated" />
              <TextField source="department" />
              <TextField source="seniority" />
              {!readOnly(permissions.permissions) && <EditButton />}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Improvements">
          {!readOnly(permissions.permissions) && (
            <Grid container justify={"flex-end"}>
              <AddImprovementsButton />
            </Grid>
          )}
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            label={false}
            reference="improvements"
            target="id"
          >
            <Datagrid expand={<ImprovementsShow />} rowClick="expand" bulkActionButtons={false}>
              <TextField source="id" label="Improvements Id" />
              <ReferenceField
                source="createdBy"
                reference="users"
                link={isInternalAdmin(permissions.permissions) ? "show" : false}
                emptyText={"System"}
                textAlign={"center"}
              >
                <TextField source="firstName" />
              </ReferenceField>
              <DateField source="created" />
              <DateField source="updated" />
              <TextField source="description" />
              {!readOnly(permissions.permissions) && <EditButton />}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const QuestionsShow = () => {
  const record = useRecordContext();

  return (
    <Box m={1}>
      <Typography variant="h6" gutterBottom component="div">
        Questions
      </Typography>
      <Table size="small">
        <TableBody>
          {record.questions?.map((question) => (
            <QuestionsRow key={question.description} question={question} />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const ImprovementsShow = () => {
  const record = useRecordContext();

  return (
    <Box m={1}>
      <Typography variant="h6" gutterBottom component="div">
        Improvements
      </Typography>
      <Table size="small">
        <TableBody>
          {record.improvements?.map((improvement) => (
            <TableRow key={improvement}>
              <TableCell component="th" scope="row">
                {improvement}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const questionsStyles = {
  row: {
    "& > *": {
      borderBottom: "unset",
    },
  },
};

const QuestionsRow = withStyles(questionsStyles)(({ classes, question }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        key={question.description}
        className={classes.row}
        hover
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          Description: {question.description}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} unmountOnExit timeout="auto">
            <Box m={1}>
              <Typography variant="h6" gutterBottom component="div">
                Hints
              </Typography>
              <Table size="small">
                <TableBody>
                  {question.hints?.map((hint) => (
                    <TableRow key={hint}>
                      <TableCell component="th" scope="row">
                        {hint}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
});
