import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  useNotify,
  Button,
  Confirm,
  ShowButton,
  useListContext,
  ListActions,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  ArrayField,
  BooleanField,
  FunctionField,
  SelectInput,
  useRecordContext,
  usePermissions,
} from "react-admin";
import { useState } from "react";
import ApiClient from "../../ApiClient";
import API from "../../Routes";
import clsx from "clsx";
import { isInternalAdmin, readOnly } from "../../auth-provider";
import SimpleChipField from "../common/utils";
import { makeStyles} from "@mui/styles";
import { DomainDisabled, DoubleArrow } from "@mui/icons-material";

const OrganisationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      label="Source"
      source="q"
      choices={[
        { id: "SIGN_UP", name: "SIGN UP" },
        { id: "ADMIN", name: "ADMIN" },
      ]}
      alwaysOn
    />
  </Filter>
);

export const OrganisationList = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <List
      {...props}
      filters={<OrganisationFilter />}
      perPage={25}
      sort={{ field: "updated", order: "DESC" }}
      actions={isInternalAdmin(permissions.permissions) ? <ListActions /> : null}
    >
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="updated" />
        <DateField source="lastDebitCredit" label="Last used credit" />
        <ReferenceArrayField
          label="BDMs"
          source="bdmIds"
          reference="internal-users"
          perPage={100}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="firstName" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="products">
          <SingleFieldList linkType={false}>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
        <TextField source="status" />
        <BooleanField
          source="openForPollination"
          label="Open For Pollination"
        />
        <FunctionField
          label="Email"
          source="billingData.email"
          render={(record) =>
            record?.billingData && ` ${record.billingData?.email} `
          }
        />
        <TextField source="source" />
        <TextField source="assessmentsSource" />
        <ShowButton />
        {!readOnly(permissions.permissions) && <DisableOrgButton />}
      </Datagrid>
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  disableButton: {
    color: "#f44336",
  },
}));

const DisableOrgButton = () => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const { refetch } = useListContext();
  const notify = useNotify();
  const isActive = record?.status === "ACTIVE";
  const classes = useStyles();

  return (
    <>
      <Button
        label={isActive ? "Disable" : "Enable"}
        className={clsx(isActive && classes.disableButton)}
        startIcon={isActive ? <DomainDisabled /> : <DoubleArrow />}
        onClick={() => setOpen(true)}
      />
      <Confirm
        isOpen={open}
        title={(isActive ? "Disable" : "Enable") + " Organisation"}
        content={`Are you sure you want to ${
          isActive ? "disable" : "enable"
        } organisation ${record?.name}?`}
        onConfirm={async () => {
          setOpen(false);
          notify(`Organisation ${isActive ? "disabled" : "enabled"}.`);
          await ApiClient.fetch(API.ORGANISATIONS.STATUS(record?.id), {
            method: "PATCH",
            body: { status: isActive ? "DISABLED" : "ACTIVE" },
          });
          refetch();
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
