import * as React from "react";
import {Admin, Resource} from 'react-admin';
import {Helmet} from 'react-helmet';
import getDataProvider from "./data-provider/data-provider";
import authProvider, {isInternalAdmin, isSuperAdmin, readOnly} from "./auth-provider";
import {OrganisationList} from "./components/organisations/organisation-list";
import {OrganisationCreate} from "./components/organisations/organisation-create";
import {OrganisationShow} from "./components/organisations/organisation-show";
import {OrganisationEdit} from "./components/organisations/organisation-edit";
import { Business, Compare, HowToReg, School, SupervisorAccount, Work, PeopleOutline, AccountTreeOutlined, Loop, FormatListBulleted, Bookmark, Search, QuestionAnswer, Ballot } from "@mui/icons-material";
import {UserCreate} from "./components/users/user-create";
import {UserShow} from "./components/users/user-show";
import {CampaignShow} from "./components/campaigns/campaign-show";
import {OccupationList} from "./components/occupations/occupation-list";
import {OccupationEdit} from "./components/occupations/occupation-edit";
import {OccupationCreate} from "./components/occupations/occupation-create";
import {CampaignList} from "./components/campaigns/campaign-list";
import {AssessmentList} from "./components/assessments/assessment-list";
import ApiClient from "./ApiClient";
import {BASE_PATH, STAGE} from "./Routes";
import {UserEdit} from "./components/users/user-edit";
import {CandidateList} from "./components/candidates/candidate-list";
import {InternalUsersList} from "./components/users/internal/internal-users-list";
import {AssessmentEdit} from "./components/assessments/assessment-edit";
import {AssessmentShow} from "./components/assessments/assessment-show";
import {QuestionsEditor} from "./components/assessments/questions/questions-editor";
import {CandidateShow} from "./components/candidates/candidate-show";
import {ImprovementsEditor} from "./components/assessments/improvements/improvements-editor";
import {UserList} from "./components/users/user-list";
import { ProjectList } from "./components/projects/project-list";
import { ProjectShow } from "./components/projects/project-show";
import { CompetencyList } from "./components/competencies/competency-list";
import { CompetencyShow } from "./components/competencies/competency-show";
import { CompetencyEdit } from "./components/competencies/competency-edit";
import { CandidatePollinationList } from "./components/candidate-pollination/candidate-pollination-list";
import { CandidatePollinationShow } from "./components/candidate-pollination/candidate-pollination-show";
import { ShortlistList } from "./components/shortlists/shortlist-list"
import { ShortlistsShow } from "./components/shortlists/shortlists-show";
import { HiredCandidatePollinationList } from "./components/hired-candidate-pollination/hired-candidate-pollination-list";
import { SkillsList } from "./components/skills/skills-list";
import { SkillCreate } from "./components/skills/skill-create";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import { SkillAssessmentList } from "./components/skill-assessments/skill-assessment-list";
import { SkillAssessmentShow } from "./components/skill-assessments/skill-assessment-show";

const httpLink = createHttpLink({
  uri: BASE_PATH + "/graphql"
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }
});

const graphqlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

const clients = {
  apolloClient: graphqlClient,
  httpClient: ApiClient.fetch
}

const dataProvider = getDataProvider(BASE_PATH, clients);

const App = () => 
  <>
    <Helmet>
      <title>Thrive Admin - {STAGE}</title>
    </Helmet>
    <Admin dataProvider={dataProvider} authProvider={authProvider} title="Thrive Admin">
        {permissions => [
            <Resource name="organisations" list={OrganisationList} create={readOnly(permissions) ? null : OrganisationCreate} edit={readOnly(permissions) ? null : OrganisationEdit} show={OrganisationShow} icon={Business} />,
            <Resource name="occupations" list={OccupationList} create={readOnly(permissions) ? null : OccupationCreate} edit={readOnly(permissions) ? null : OccupationEdit} icon={Work} />,
            <Resource name="users" options={{label: 'Customer Users'}} list={isInternalAdmin(permissions) ? UserList : null} create={readOnly(permissions) ? null : UserCreate} edit={readOnly(permissions) ? null : UserEdit} show={isInternalAdmin(permissions) ? UserShow : null} icon={PeopleOutline}/>,
            <Resource name="internal-users" options={{label: 'Internal Users'}} list={isInternalAdmin(permissions) ? InternalUsersList : null} create={isSuperAdmin(permissions) ? UserCreate : null} edit={isSuperAdmin(permissions) ? UserEdit : null} icon={SupervisorAccount}/>,
            <Resource name="credit-transactions" />,
            <Resource name="campaigns" list={CampaignList} show={CampaignShow} icon={Compare} />,
            <Resource name="projects" list={ProjectList} show={ProjectShow} icon={AccountTreeOutlined} />,
            <Resource name="candidates" list={CandidateList} show={CandidateShow} icon={School}/>,
            <Resource name="assessments" list={AssessmentList} show={AssessmentShow} icon={QuestionAnswer} edit={isSuperAdmin(permissions) ? AssessmentEdit : null}/>,
            <Resource name="scores" />,
            <Resource name="results" />,
            <Resource name="results/overall" />,
            <Resource name="employees" />,
            <Resource name="profiles" />,
            <Resource name="competencies" list={CompetencyList}  show={CompetencyShow} edit={isSuperAdmin(permissions) ? CompetencyEdit : null} icon={HowToReg} />,
            <Resource name="skill-assessments" list={SkillAssessmentList} show={SkillAssessmentShow} options={{label: 'Skill Assessments'}} icon={Ballot} />,
            <Resource name="competency-group-development-tips" />,
            <Resource name="questions" create={readOnly(permissions) ? null : QuestionsEditor} edit={readOnly(permissions) ? null : QuestionsEditor}/>,
            <Resource name="improvements" create={readOnly(permissions) ? null : ImprovementsEditor} edit={readOnly(permissions) ? null : ImprovementsEditor}/>,
            <Resource name="candidate-pollination" options={{label: 'Pollination Candidates'}} list={isInternalAdmin(permissions) ? CandidatePollinationList : null} show={CandidatePollinationShow} icon={Loop} />,
            <Resource name="shortlists" options={{label: 'Shortlists'}} list={isInternalAdmin(permissions) ? ShortlistList : null} show={ShortlistsShow} icon={FormatListBulleted} />,        
            <Resource name="candidate-pollination-hired" options={{label: 'Hired Pollination Candidates'}} list={isInternalAdmin(permissions) ? HiredCandidatePollinationList : null} icon={Search}/>,
            <Resource name="skills" options={{label: 'Candidate Skills'}} list={SkillsList} create={readOnly(permissions) ? null : SkillCreate} icon={Bookmark}/>,
            <Resource name="projectTeams" />
        ]}
    </Admin>
  </>

export default App;