import { fetchUtils, GET_MANY } from "react-admin";

const initProjectTeamsProvider = (apiUrl, httpClient = fetchUtils.fetchJson, defaultCrudHandler, convertDataRequestToHTTP) => {
    return (type, resource, params) => {
        switch (type) {
            case GET_MANY:
                return httpClient(`${apiUrl}/teams?ids=${params.ids}`)
                    .then(response => ({
                        data: response.json.content,
                        total: parseInt(response.json.totalElements, 10)
                    }));

            default:
                return defaultCrudHandler(type, resource, params);
        }
    }
};

export default initProjectTeamsProvider;