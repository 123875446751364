import * as React from "react";
import { Link } from 'react-router-dom';
import {Button, useShowContext} from "react-admin";
import { Box } from "@mui/material";
import { Add } from "@mui/icons-material";

const AddQuestionsButton = (props) => {
    const {record} = useShowContext();

    return (
        <Box mt={1} mb={1}>
            <Button
                variant="contained"
                component={Link}
                to={{
                    pathname: "/questions/create",
                    state: {record: {assessmentId: record.id}},
                }}
                label="Add Questions"
            >
                <Add/>
            </Button>
        </Box>
    );
};

export default (AddQuestionsButton);