import * as React from "react";
import {
  EditButton,
  EmailField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";

const UserShowActions = (props) => {
  const record = useRecordContext();
  
  return (
    <TopToolbar {...props}>
      <ShowButton
        to={{ pathname: `/organisations/${record?.organisationId}/show` }}
        label="Organisation"
      />
      <EditButton record={record} />
    </TopToolbar>
  );
};

export const UserShow = (props) => (
  <Show {...props} actions={<UserShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <TextField source="role" />
    </SimpleShowLayout>
  </Show>
);
