import {
  Button,
  Datagrid,
  downloadCSV,
  EditButton,
  EmailField,
  List,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
  usePermissions,
} from "react-admin";
import * as React from "react";
import { cloneElement } from "react";
import AddUserButton from "../../organisations/add-user-button";
import DisableUserButton from "../disable-user-button";
import ApiClient from "../../../ApiClient";
import API from "../../../Routes";
import { isSuperAdmin } from "../../../auth-provider";
import { FileDownload } from "@mui/icons-material";

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter, total } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <AddUserButton internalUsers={true} />
      <Button
        disabled={total === 0}
        onClick={async () => {
          const exportResponse = await ApiClient.fetch(
            API.USERS.EXPORT("INTERNAL")
          );
          const data = exportResponse.body;
          downloadCSV(data, "internal-users");
        }}
        label="Export Internal Users"
      >
        <FileDownload />
      </Button>
    </TopToolbar>
  );
};

export const InternalUsersList = ({ ...props }) => {
  const permissions = usePermissions();

  return (
    <List
      {...props}
      actions={isSuperAdmin(permissions.permissions) ? <ListActions /> : null}
      perPage={25}
      sort={{ field: "updated", order: "DESC" }}
      filter={{userType: 'INTERNAL'}}
    >
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="role" />
        {isSuperAdmin(permissions.permissions) && <EditButton />}
        {isSuperAdmin(permissions.permissions) && <DisableUserButton />}
      </Datagrid>
    </List>
  );
};
