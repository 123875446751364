import * as React from "react";
import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const valueArrayToObject = (items) =>
  items.map((item) => ({ id: item, name: item }));

const useStyles = makeStyles({
  chip: { margin: 4, cursor: "inherit" },
});

const SimpleChipField = () => {
  const classes = useStyles();
  const record = useRecordContext();

  return record ? (
    <Chip key={record} className={classes.chip} label={record} />
  ) : null;
};

SimpleChipField.defaultProps = {
  label: false,
};

export default SimpleChipField;
