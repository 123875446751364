import { useState } from "react";
import { Button, Confirm, useListContext, useNotify, useRecordContext } from "react-admin";
import ApiClient from "../../ApiClient";
import API from "../../Routes";
import * as React from "react";
import { makeStyles } from "@mui/styles";
import { DoubleArrow, Block } from "@mui/icons-material";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  disableButton: {
    color: "#f44336",
  },
}));

const DisableUserButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const { refetch } = useListContext();
  const isActive = record.status === "ACTIVE";
  const classes = useStyles();

  return (
    <>
      <Button
        label={isActive ? "Disable" : "Enable"}
        className={clsx(isActive && classes.disableButton)}
        startIcon={isActive ? <Block /> : <DoubleArrow />}
        onClick={() => setOpen(true)}
      />
      <Confirm
        isOpen={open}
        title={isActive ? "Disable" : "Enable"}
        content={`Are you sure you want to ${isActive ? "disable" : "enable"} ${
          record.firstName
        } ${record.lastName}?`}
        onConfirm={async () => {
          setOpen(false);
          notify(`User ${isActive ? "disabled" : "enabled"}.`);
          await ApiClient.fetch(API.USERS.STATUS(record.id), {
            method: "PATCH",
            body: { status: isActive ? "DISABLED" : "ACTIVE" },
          });
          refetch();
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default DisableUserButton;
