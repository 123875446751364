import {
  DateField,
  NumberField,
  Tab,
  TextField,
  Show,
  TabbedShowLayout,
} from "react-admin";
import * as React from "react";

export const CompetencyShow = ({ ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="id" />
        <DateField source="created" />
        <DateField source="updated" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="instrumentId" label="Aon Id" />
        <NumberField source="durationInSeconds" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
