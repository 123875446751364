import { fetchUtils, GET_LIST } from "react-admin";

const initOrganisationsProvider = (apiUrl, httpClient = fetchUtils.fetchJson, defaultCrudHandler) => {
    return (type, resource, params) => {
        switch (type) {
            case GET_LIST:
                return defaultCrudHandler(type, `${resource}/search`, params);
            default:
                return defaultCrudHandler(type, resource, params);
        }
    }
};

export default initOrganisationsProvider;